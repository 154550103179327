<template>
  <div class="pbf">
     <BrandTabProducts
      :inurl="'/brands/' + props.id + '/products'"
      :brand-id="props.id"
      :category="'nutrient'"
      :intags="{}"
      />
  </div>
</template>

<script setup lang="ts">

interface Props {
  id: number
}

const props = defineProps<Props>()

</script>